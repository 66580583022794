import { useCallback, useMemo, useState, useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useSearchParams, Outlet } from "react-router-dom";
import classnames from "classnames";

import { BusinessDashboardMobileDevice } from "common/proof_frame/mobile_nav/empty_states";
import { useUpcomingPath, useHistoryPath } from "common/closing_agenda";
import { BulkActionBar } from "common/core/bulk_actions";
import {
  BulkActionClearButton,
  BulkActionSeparator,
  useBulkActions,
} from "common/core/bulk_actions/common";
import {
  BulkTransactionDeleteModal,
  BulkActionDeleteButton,
  useBulkDelete,
} from "common/dashboard/bulk_actions/transaction_delete";
import {
  BulkActionArchiveButton,
  BulkActionUnarchiveButton,
  useBulkArchive,
} from "common/dashboard/bulk_actions/transaction_archive";
import LoadingIndicator from "common/core/loading_indicator";
import Link from "common/core/link";
import { BusinessGettingStarted } from "common/dashboard/getting_started/";
import { usePermissions } from "common/core/current_user_role";
import {
  DashboardBody,
  DashboardContent,
  DashboardTitle,
  DashboardHeader,
  DashboardFilters,
  DashboardActionBar,
} from "common/dashboard";
import {
  StatusFilter,
  ClearAllButton,
  getActiveFiltersCount,
  useDateConstraint,
  BusinessDashboardDateFilter as DateFilter,
  TransactionSectionNamespace,
  useToggleSetWithCallback,
  TransactionSubsectionNamespace,
  type DateConstraint,
} from "common/dashboard/filter_dropdown/common";
import {
  businessDashboardDeserializer,
  businessDashboardSerializer,
} from "business_portal/dashboard/filter";
import {
  BusinessTransactionNameColumn,
  BusinessTransactionTypeColumn,
  DateCreatedColumn,
  DateCompletedColumn,
  SenderColumn,
  RecipientNameColumn,
  StatusColumn,
  OrganizationNameColumn,
  RiskLevelColumn,
  KebabMenuColumn,
  type KebabMenuItem,
} from "common/dashboard/columns";
import { BulkTable } from "common/core/table";
import TablePagination, { usePagination } from "common/core/table/pagination";
import TableSearch from "common/core/table/search";
import StarterUpgrade from "business_portal/transactions/list//starter_upgrade";
import {
  Feature,
  OrganizationTransactionDetailedStatus,
  OrganizationTransactionStatus,
  TransactionScope,
  type OrganizationTransactionColumn,
  type SortDirection,
} from "graphql_globals";
import { useActiveOrganization } from "common/account/active_organization";
import { useQuery, useMutation } from "util/graphql";
import { activeTier, showUpgrade } from "util/trial_upgrade";
import {
  businessTransactionEditRouteV2,
  transactionDetailsRouteV2,
  cloneTransactionRoute,
} from "util/routes";
import { useFeatureFlag } from "common/feature_gating";
import { SCHEDULED_PATH } from "common/closing_agenda/path";
import { PATH as NOTARY_MEETING_HISTORY_PATH } from "common/notary/meeting_history/path";
import { usePrependedDocumentTitle, useId } from "util/html";
import AlertMessage from "common/core/alert_message";
import { useDebouncedQuery, useFilter } from "common/dashboard/filter";
import { useIAnav, useProofDefendUpsell, useCloneTransaction } from "util/feature_detection";
import {
  BIZ_REFERRALS_ANNOUNCEMENT_DISMISSED_TAG,
  BIZ_TEMPLATES_ANNOUNCEMENT_DISMISSED_TAG,
} from "business_portal/dashboard";
import { isNotaryNST } from "common/notary/capacity";
import EmptyResults from "common/table/empty_results";
import DefendEmptyState from "common/table/defend_empty_state";
import { CLONE_TRANSACTION } from "constants/feature_gates";

import DeleteOrganizationTransactionsMutation from "./delete_organization_transactions.mutation.graphql";
import BusinessTransactionsGraph, {
  type BusinessTransactions_node_Organization_transactions_edges_node as OrganizationTransaction,
} from "./transactions.query.graphql";
import BusinessDashboardGraph, {
  type BusinessTransactionRecords_node_Organization as ActiveOrganization,
  type BusinessTransactionRecords_viewer as Viewer,
} from "./index.query.graphql";
import { triggerAuthorizationFlow } from "../dashboard/dotloop_wizard/requests";
import AdobeEnabledEmptyState from "./list/adobe_enabled";
import { DotLoopModal, useDotLoop } from "../dashboard/dotloop";
import { SignOnDeviceModal, useSignOnDevice } from "../dashboard/menus/sign_on_device";
import { NewTransactionPopoutMenu } from "../dashboard/menus/new_transaction_popout_menu";
import Styles from "./index.module.scss";

type Props = {
  activeOrganization: ActiveOrganization;
  viewer: Viewer;
};

const PAGE_SIZE = 50;

const MESSAGES = defineMessages({
  searchPlacholder: {
    id: "3705852e-6a32-4da4-a0ab-1fd5f317f85f",
    defaultMessage:
      "Search by {transactionNameColumnEnabled, select, true {transaction name or ID, or signer name, or email} other {name, email, or transaction ID}}",
  },
  searchLabel: {
    id: "68893c86-c76d-4ead-941f-a3cdfe803d9b",
    defaultMessage: "Search for a transaction",
  },
  deleteTransaction: {
    id: "e2a451bc-53db-4c3e-aa85-9419d54dbbf3",
    defaultMessage: "Delete transaction",
  },
  caption: {
    id: "2f335559-7c3d-4288-9451-7ccd58f566da",
    defaultMessage: "Transactions",
  },
  pageTitle: {
    id: "1be00148-62a2-4b12-942f-0909111a6803",
    defaultMessage: "Transaction Dashboard",
  },
  deleteSingleTxn: {
    id: "a451277d-7bae-41a6-a6c3-31bfcc9f85c1",
    defaultMessage: "Transaction deleted.",
  },
});

const inProgressStatuses = [
  OrganizationTransactionDetailedStatus.SENT_TO_SIGNER,
  OrganizationTransactionDetailedStatus.ACTIVE,
  OrganizationTransactionDetailedStatus.VIEWED,
  OrganizationTransactionDetailedStatus.PARTIALLY_COMPLETE,
  OrganizationTransactionDetailedStatus.ATTEMPTED,
  OrganizationTransactionDetailedStatus.AWAITING_PAYMENT,
];

const otherOptions = [
  [OrganizationTransactionStatus.LIVE, OrganizationTransactionDetailedStatus.MEETING_IN_PROGRESS],
  [OrganizationTransactionStatus.SUCCESS, OrganizationTransactionDetailedStatus.COMPLETE],
  [
    OrganizationTransactionStatus.SUCCESS,
    OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
  ],
  [OrganizationTransactionStatus.INACTIVE, OrganizationTransactionDetailedStatus.DRAFT],
] as [OrganizationTransactionStatus, OrganizationTransactionDetailedStatus][];

function TransactionRecords({ viewer, activeOrganization }: Props) {
  const { user } = viewer;
  const { dotloopConnected, dotloopEligible, notaryProfile } = user!;

  const intl = useIntl();
  const isIaNav = useIAnav();
  const cloneTransactionEnabled = useFeatureFlag(CLONE_TRANSACTION);
  const canUseCloneTransaction = useCloneTransaction();
  const tableSearchResultsId = useId();
  const navigate = useNavigate();
  const [rawQueryArgs] = useSearchParams();
  const { showDotloopModal, enableDotloopModal, closeDotloopModal } = useDotLoop();
  const meetingPath = useUpcomingPath(SCHEDULED_PATH);
  const historyPath = useHistoryPath(NOTARY_MEETING_HISTORY_PATH);
  const { hasPermissionFor } = usePermissions();
  const proofDefendUpsell = useProofDefendUpsell(activeOrganization);

  usePrependedDocumentTitle(intl.formatMessage(MESSAGES.pageTitle));

  const getTransactionLinkProps = useCallback(
    (transaction: OrganizationTransaction) => {
      const { id, detailedStatus, documentBundle, transactionVariant } = transaction;

      const meetingToJoin = documentBundle?.meetings.edges.find(
        ({ node: meeting }) => meeting.canJoin,
      );

      if (meetingToJoin) {
        return { to: `/meeting/${meetingToJoin.node.id}` };
      }

      let route;
      if (detailedStatus === OrganizationTransactionDetailedStatus.DRAFT) {
        route = businessTransactionEditRouteV2({
          id,
          variant: transactionVariant,
          queryParams: rawQueryArgs,
        });
      } else {
        route = transactionDetailsRouteV2({ id, queryParams: rawQueryArgs });
      }

      return {
        to: route,
        role: detailedStatus === OrganizationTransactionDetailedStatus.DRAFT ? undefined : "button",
      };
    },
    [rawQueryArgs],
  );

  const { handleChange, deserializedArgs } = useFilter(
    businessDashboardDeserializer,
    businessDashboardSerializer,
  );

  const {
    section,
    subSection,
    createdDateConstraint,
    completedDateConstraint,
    page: pageIndex,
    query,
    sortColumn,
    sortDirection,
    detailedStatuses: selectedStatuses,
  } = deserializedArgs;

  const { textFilterValue, handleTextFilterChange } = useDebouncedQuery(handleChange, query, true);

  const setCreatedDateConstraint = useCallback(
    (createdDateConstraint: DateConstraint | null) => {
      handleChange({
        createdDateConstraint,
      });
    },
    [handleChange],
  );

  const { startDate: createdDateStart, endDate: createdDateEnd } = useDateConstraint(
    createdDateConstraint,
    setCreatedDateConstraint,
  );

  const setCompletedDateConstraint = useCallback(
    (completedDateConstraint: DateConstraint | null) => {
      handleChange({
        completedDateConstraint,
      });
    },
    [handleChange],
  );

  const { startDate: completedDateStart, endDate: completedDateEnd } = useDateConstraint(
    completedDateConstraint,
    setCompletedDateConstraint,
  );

  const setPageIndex = useCallback(
    (page: number) => {
      handleChange({ page });
    },
    [handleChange],
  );

  const orgFilterState =
    section === TransactionSectionNamespace.ALL &&
    subSection === TransactionSubsectionNamespace.CREATED_BY_ME
      ? TransactionScope.created_by_me
      : null;

  const riskyTransactionsOnly =
    section === TransactionSectionNamespace.ALL &&
    subSection === TransactionSubsectionNamespace.IDENTITY_RISK;

  const setSortValue = useCallback(
    (sortColumn: OrganizationTransactionColumn, sortDirection?: SortDirection) => {
      handleChange({ sortColumn, sortDirection });
    },
    [handleChange],
  );

  const sortBy =
    sortColumn && sortDirection ? { column: sortColumn, direction: sortDirection } : undefined;

  const {
    clearSelection: clearStatusesSelection,
    removeAll: removeAllStatuses,
    addAll: addAllStatuses,
    toggleSelection: toggleStatusSelection,
  } = useToggleSetWithCallback(selectedStatuses, handleChange, "detailedStatuses");

  const variables = {
    offset: PAGE_SIZE * pageIndex,
    query,
    organizationId: activeOrganization.id,
    scope: orgFilterState,
    detailedStatuses: Array.from(selectedStatuses.values()),
    archived: section === TransactionSectionNamespace.ARCHIVED,
    riskyTransactionsOnly,
    sortBy: sortBy ? [sortBy] : [],
    createdDateStart,
    createdDateEnd,
    completedDateStart,
    completedDateEnd,
  };
  const activeFiltersCount = getActiveFiltersCount(variables);

  const deleteOrganizationTransactionsMutateFn = useMutation(
    DeleteOrganizationTransactionsMutation,
  );

  const { data, loading, previousData } = useQuery(BusinessTransactionsGraph, { variables });

  const organization = data
    ? data.node!
    : loading && previousData
      ? previousData.node! // previousData is used to make sure dashboard isn't just wiped clean while querying for new data
      : null;

  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const transactions = organization ? organization.transactions : null;
  const totalCount = transactions?.totalCount ?? 0;
  const pageCount = Math.max(Math.ceil(totalCount / PAGE_SIZE), 1);
  const transactionNameColumnEnabled = activeOrganization.transactionNameSearchEnabled;

  const items = useMemo(
    () => (transactions ? transactions.edges.map(({ node }) => node) : []),
    [transactions],
  );

  const {
    toggleItem,
    toggleAllItems,
    clearAllItems,
    selectedItemCount,
    selectedItemIdsSet,
    selectedItemIdsArray,
    selectAllCheckboxState,
  } = useBulkActions(items);

  const { preventDelete, showBulkDeleteModal, setShowBulkDeleteModal } = useBulkDelete(
    items,
    selectedItemIdsArray,
  );

  const { preventArchive } = useBulkArchive(items, selectedItemIdsArray);

  const { showSignOnDeviceModal, setShowSignOnDeviceModal } = useSignOnDevice();

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: loading,
      pageIndex,
      pageCount,
      pageSize: PAGE_SIZE,
      items,
      onPageChange: setPageIndex,
    });

  const handleRowClick = useCallback(
    (transaction: OrganizationTransaction) => {
      const { to } = getTransactionLinkProps(transaction);
      navigate(to);
    },
    [navigate, getTransactionLinkProps],
  );

  // TODO: BIZ-7379 - call upon clone api
  const handleCloneClick = (transaction: OrganizationTransaction) => {
    const link = cloneTransactionRoute(transaction);
    navigate(link);
  };

  const viewingAllTransactions =
    orgFilterState !== TransactionScope.created_by_me &&
    section === TransactionSectionNamespace.ALL;

  const handleImportLoop = () => {
    if (dotloopConnected) {
      enableDotloopModal();
    } else {
      triggerAuthorizationFlow();
    }
  };

  const bulkUnarchiveButton = (
    <BulkActionUnarchiveButton ids={selectedItemIdsArray} selectedItemCount={selectedItemCount} />
  );

  const bulkArchiveButton = (
    <BulkActionArchiveButton
      ids={selectedItemIdsArray}
      selectedItemCount={selectedItemCount}
      disabled={preventArchive}
    />
  );

  const bulkDeleteButton = (
    <BulkActionDeleteButton onClick={() => setShowBulkDeleteModal(true)} disabled={preventDelete} />
  );

  const easylinkManagementButtons = (
    <>
      {hasPermissionFor("createOrganizationTransactions") && (
        <NewTransactionPopoutMenu
          dotloopEligible={dotloopEligible}
          handleImportLoop={handleImportLoop}
          orgIsActive={activeOrganization.active}
          notaryProfile={notaryProfile}
        />
      )}
    </>
  );

  const onDelete = () => {
    return deleteOrganizationTransactionsMutateFn({
      variables: {
        ...variables,
        input: {
          ids: selectedItemIdsArray,
        },
      },
    });
  };

  const allTransactionsCount = organization?.allTransactionsCount.totalCount ?? 0;
  const createdByMeTransactionsCount = organization?.createdByMeTransactionsCount.totalCount ?? 0;

  const transactionDashboardDescription = isIaNav ? (
    <FormattedMessage
      id="00ae247b-65fc-442e-be8d-99e535e0a72b"
      defaultMessage={`{createdByMe, select, true {This table shows transactions that you have initiated.} other {This table shows all transactions initiated by you and your team.}}`}
      values={{
        createdByMe: orgFilterState === TransactionScope.created_by_me,
      }}
    />
  ) : (
    <FormattedMessage
      id="00ae247b-65fc-442e-be8d-99e535e0a72b"
      defaultMessage={`{createdByMe, select, true {This table shows transactions that you have initiated.} other {This table shows all transactions initiated by you and your team.}}{linkToAcademy, select, true { Visit <academy>Notarize Academy</academy> to learn more about sending transactions.}other{}}{isNotary, select, true{ To view upcoming Notary meetings you have initiated, visit <scheduled>Scheduled Meetings</scheduled>. To view all past meetings, visit <history>Meeting History</history>.} other{}}`}
      values={{
        isNotary: Boolean(notaryProfile),
        createdByMe: orgFilterState === TransactionScope.created_by_me,
        history: (text) => <Link to={historyPath}>{text}</Link>,
        scheduled: (text) => <Link to={meetingPath}>{text}</Link>,
        linkToAcademy: Boolean(allTransactionsCount) && Boolean(!notaryProfile),
        academy: (text) => (
          <Link href="https://www.notarize.com/academy/business-academy-training-for-all-notarize-platform-users#how-to-send-a-notarization-request">
            {text}
          </Link>
        ),
      }}
    />
  );

  const archivedDashboardTitle = (
    <DashboardTitle
      title={
        <FormattedMessage id="3e797498-957b-4bbe-912b-6855bc41a689" defaultMessage="Archived" />
      }
      description={
        <FormattedMessage
          id="a4d00737-daad-43be-8f60-1b81dd419da8"
          defaultMessage="This table shows transactions that have been archived by you and your team."
        />
      }
      buttons={easylinkManagementButtons}
    />
  );

  const dashboardTitle =
    subSection === TransactionSubsectionNamespace.IDENTITY_RISK ? (
      <DashboardTitle
        title={
          <FormattedMessage
            id="52521b44-dc2d-4dad-84ee-5585ecafb7d3"
            defaultMessage="Risk Alerts"
          />
        }
        buttons={easylinkManagementButtons}
      />
    ) : (
      <DashboardTitle
        title={
          <FormattedMessage
            id="ecf8203b-226b-43ea-8e2f-8ff24b984d83"
            defaultMessage="All Transactions"
          />
        }
        description={transactionDashboardDescription}
        buttons={easylinkManagementButtons}
      />
    );

  const onEmptyCreatedByMe =
    orgFilterState === TransactionScope.created_by_me && createdByMeTransactionsCount === 0;
  const showEmptyResults = totalCount === 0 && allTransactionsCount > 0 && !onEmptyCreatedByMe;

  const [bodyContent, setBodyContent] = useState<
    | "transactions"
    | { noTransactions: "getting-started" | "upgrade" | "empty" | "adobe-empty" | "defend-empty" }
  >(
    totalCount === 0 && !loading
      ? {
          noTransactions: getNoTransactionsState(),
        }
      : "transactions",
  );

  function getNoTransactionsState() {
    if (showUpgrade(activeOrganization) && viewingAllTransactions) {
      return "upgrade";
    } else if (subSection === TransactionSubsectionNamespace.IDENTITY_RISK && proofDefendUpsell) {
      return "defend-empty";
    } else if (
      showEmptyResults ||
      !activeOrganization.active ||
      !hasPermissionFor("createOrganizationTransactions")
    ) {
      return "empty";
    } else if (activeOrganization.adobeEnabled) {
      return "adobe-empty";
    }
    return "getting-started";
  }

  useEffect(() => {
    if (totalCount === 0 && !loading) {
      setBodyContent({ noTransactions: getNoTransactionsState() });
    } else {
      setBodyContent("transactions");
    }
  }, [totalCount, loading]);

  const kebabMenuItems: KebabMenuItem<OrganizationTransaction> = [
    {
      label: "View",
      onClick: handleRowClick,
    },
  ];

  if (canUseCloneTransaction) {
    kebabMenuItems.push({
      label: "Clone",
      onClick: handleCloneClick,
    });
  }

  const columns = [
    ...(transactionNameColumnEnabled ? [BusinessTransactionNameColumn] : []),
    BusinessTransactionTypeColumn(intl),
    ...(activeOrganization.proofDefend ? [RiskLevelColumn] : []),
    StatusColumn,
    OrganizationNameColumn,
    RecipientNameColumn(intl),
    SenderColumn,
    DateCreatedColumn(sortBy, setSortValue),
    DateCompletedColumn(sortBy, setSortValue),
    ...(cloneTransactionEnabled // TODO: BIZ-7413, remove ld gate of kebab menu
      ? [KebabMenuColumn<OrganizationTransaction>(intl, kebabMenuItems)]
      : []),
  ];

  const renderBody = () => {
    if (bodyContent === "transactions") {
      return (
        <BulkTable
          toggleItem={toggleItem}
          toggleAllItems={toggleAllItems}
          selectedItemIds={selectedItemIdsSet}
          selectAllCheckboxState={selectAllCheckboxState}
          data={items}
          columns={columns}
          loading={loading}
          caption={intl.formatMessage(MESSAGES.caption)}
          rowInteraction={{
            onClick: handleRowClick,
            getLinkProps: getTransactionLinkProps,
          }}
          totalItems={totalCount}
          getAutomationId={(transaction: OrganizationTransaction) =>
            `transaction-row-${transaction.id}`
          }
          stickyHotDogMenu={cloneTransactionEnabled} // TODO: BIZ-7413, remove ld gate of kebab menu
        />
      );
    } else if (bodyContent.noTransactions === "upgrade") {
      return <StarterUpgrade activeTier={activeTier(activeOrganization)} />;
    } else if (bodyContent.noTransactions === "empty") {
      return (
        <div className={Styles.emptyState}>
          <EmptyResults id={tableSearchResultsId} />
        </div>
      );
    } else if (bodyContent.noTransactions === "adobe-empty") {
      return (
        <div className={Styles.emptyState}>
          <AdobeEnabledEmptyState />
        </div>
      );
    } else if (bodyContent.noTransactions === "defend-empty") {
      return <DefendEmptyState />;
    }
    return (
      <BusinessGettingStarted
        dotloopEligible={dotloopEligible}
        handleImportLoop={handleImportLoop}
        isNstNotary={isNotaryNST(viewer.user!.notaryProfile)}
      />
    );
  };

  return (
    <>
      <div
        className={classnames(
          Styles.dashboardWithBanner,
          isIaNav && Styles.iaNavDashboardWithBanner,
        )}
      >
        <DashboardContent>
          {section === TransactionSectionNamespace.ARCHIVED
            ? archivedDashboardTitle
            : dashboardTitle}

          <DashboardHeader>
            {activeOrganization.adobeEnabled && (
              <AlertMessage kind="info" className={Styles.adobeBanner}>
                <FormattedMessage
                  id="60d2d6c5-8d60-4394-9f41-e88f37e94ec4"
                  defaultMessage="Your account is integrated with Adobe Sign. Please log in to your Adobe Sign account to send and manage Proof transactions."
                />
              </AlertMessage>
            )}
            <DashboardFilters>
              <TableSearch
                value={textFilterValue}
                placeholder={intl.formatMessage(MESSAGES.searchPlacholder, {
                  transactionNameColumnEnabled,
                })}
                aria-label={intl.formatMessage(MESSAGES.searchLabel)}
                onChange={handleTextFilterChange}
                aria-describedby={showEmptyResults ? tableSearchResultsId : undefined}
              />
              {activeOrganization.featureList.includes(Feature.TRANSACTION_FILTERING) &&
                section !== TransactionSectionNamespace.ARCHIVED && (
                  <StatusFilter
                    selectedStatuses={selectedStatuses}
                    inProgressStatuses={inProgressStatuses}
                    otherOptions={
                      activeOrganization.featureList.includes(Feature.SIGNING_SCHEDULE)
                        ? otherOptions.concat([
                            [
                              OrganizationTransactionStatus.FAILURE,
                              OrganizationTransactionDetailedStatus.EXPIRED,
                            ],
                          ])
                        : otherOptions
                    }
                    clearSelection={clearStatusesSelection}
                    removeAll={removeAllStatuses}
                    addAll={addAllStatuses}
                    toggleSelection={toggleStatusSelection}
                    disabled={loading}
                    data-automation-id="filter-dropdown-status"
                  />
                )}
              <DateFilter
                label={
                  <FormattedMessage
                    id="78c102cc-5ff5-42e6-a4d1-90fbc373a781"
                    defaultMessage="Date created"
                  />
                }
                data-automation-id="filter-dropdown-date-created"
                disabled={loading}
                setSelectedConstraint={setCreatedDateConstraint}
                selectedConstraint={createdDateConstraint}
              />
              <DateFilter
                label={
                  <FormattedMessage
                    id="dc18edd6-330a-438b-8a8a-d723fd64030f"
                    defaultMessage="Date completed"
                  />
                }
                data-automation-id="filter-dropdown-date-completed"
                disabled={loading}
                setSelectedConstraint={setCompletedDateConstraint}
                selectedConstraint={completedDateConstraint}
              />
              {activeFiltersCount > 0 && (
                <ClearAllButton
                  activeFiltersCount={activeFiltersCount}
                  onClick={() => {
                    handleTextFilterChange({ value: "" });
                    handleChange(
                      {
                        section,
                        subSection,
                      },
                      true,
                    );
                  }}
                />
              )}
            </DashboardFilters>
          </DashboardHeader>

          {bodyContent === "transactions" && (
            <DashboardActionBar
              pagination={
                <TablePagination
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  totalCount={totalCount}
                />
              }
              showDownloadWarning={activeOrganization.downloadWarningEnabled}
            >
              {selectedItemCount > 0 && (
                <BulkActionBar itemCount={selectedItemCount}>
                  <BulkActionClearButton onClick={clearAllItems} />
                  <BulkActionSeparator />
                  {section === TransactionSectionNamespace.ARCHIVED
                    ? bulkUnarchiveButton
                    : bulkArchiveButton}
                  {section !== TransactionSectionNamespace.ARCHIVED &&
                    hasPermissionFor("deleteOrganizationTransactions") &&
                    bulkDeleteButton}
                </BulkActionBar>
              )}
            </DashboardActionBar>
          )}

          <DashboardBody>{renderBody()}</DashboardBody>
        </DashboardContent>
      </div>

      {showBulkDeleteModal && (
        <BulkTransactionDeleteModal
          onDelete={onDelete}
          onClose={() => setShowBulkDeleteModal(false)}
          totalTransactions={selectedItemIdsArray.length}
        />
      )}
      {showDotloopModal && <DotLoopModal onClose={closeDotloopModal} />}
      {showSignOnDeviceModal && (
        <SignOnDeviceModal onClose={() => setShowSignOnDeviceModal(false)} />
      )}
      <Outlet />
    </>
  );
}

function BusinessTransactions({ showMobileDeviceContent }: { showMobileDeviceContent: boolean }) {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(BusinessDashboardGraph, {
    variables: {
      organizationId: activeOrganizationId!,
      tagList: [BIZ_TEMPLATES_ANNOUNCEMENT_DISMISSED_TAG, BIZ_REFERRALS_ANNOUNCEMENT_DISMISSED_TAG],
      skip: showMobileDeviceContent,
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const viewer = data!.viewer;
  const organization = data!.node!;
  if (organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  return showMobileDeviceContent ? (
    <BusinessDashboardMobileDevice />
  ) : (
    <TransactionRecords activeOrganization={organization} viewer={viewer} />
  );
}

export default BusinessTransactions;
